body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* body {
  animation: mymove 10s infinite;
}
@keyframes mymove {
  from {
    background-color: rgb(135, 48, 216);
  }
  to {
    background-color: rgb(49, 49, 136);
  }
} */
/* html, body {
  width: 100%;
  height:100%;
} */

/* body {
    background: linear-gradient(-45deg, #db6642, #e73c7e, #b11b84, #5223d5);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
} */